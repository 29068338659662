import { useIsMobile } from '@energiebespaarders/hooks';
import { Button } from '@energiebespaarders/symbols';
import { ButtonProps } from '@energiebespaarders/symbols/components/Button';
import mediaMin from '@energiebespaarders/symbols/styles/breakpoints';
import { useMemo } from 'react';
import styled from 'styled-components';
import { margin } from '~/styles/mixins';
import { dummy_dummy_content_ButtonRecord } from '~/types/generated/cms/dummy';

const StyledButton = styled(Button)`
  margin: 0;

  &:has(+ &) {
    ${margin(1, 'b')};

    ${mediaMin.md} {
      ${margin(1, 'r')};
    }
  }
`;

interface Props {
  block: dummy_dummy_content_ButtonRecord;
  customStyle?: any;
}

type ButtonType = 'Primary' | 'Secondary';

const getButtonProps = (buttonType: ButtonType) => {
  switch (buttonType) {
    case 'Primary':
      return {
        bgColor: 'yellow',
        color: 'grayBlack',
      };

    case 'Secondary':
      return {
        bgColor: 'purpleDarker',
        inverse: true,
      };
    default:
      return {};
  }
};

const DatoButton: React.FC<Props & ButtonProps> = ({ block, customStyle, ...buttonProps }) => {
  const isMobile = useIsMobile();
  const { buttonType, label, url, openInNewTab } = block;

  const variantProps = getButtonProps(buttonType as ButtonType);

  const { to, href } = useMemo(() => {
    if (url?.includes('energiebespaarders.nl')) {
      return { to: url, href: undefined };
    }
    return { href: url || undefined };
  }, [url]);

  return (
    <StyledButton
      px={5}
      fluid={isMobile}
      style={{ ...customStyle }}
      to={to}
      href={href}
      label={label || ''}
      target={openInNewTab ? '_blank' : undefined}
      rel={openInNewTab && !to ? 'noopener noreferrer' : undefined}
      {...variantProps}
      {...buttonProps}
    />
  );
};

export default DatoButton;
